<template>
  <div
    class="container size-mobile bg-element-white min-height-second paket"
    style="font-family: Montserrat;padding-left:15px !important;"
  >
    <div class="d-flex bd-highlight mt-3">
        <div class="p-2 bd-highlight">Hari</div>
        <div class="p-2 bd-highlight">:</div>
        <div class="p-2 bd-highlight flex-grow-1 "><strong>Selasa</strong></div>
    </div>
    <div class="d-flex bd-highlight">
        <div class="p-2 bd-highlight">Tanggal</div>
        <div class="p-2 bd-highlight">:</div>
        <div class="p-2 bd-highlight flex-grow-1 "><strong>20 April 2021</strong></div>
    </div>
    <div class="d-flex bd-highlight">
        <div class="p-2 bd-highlight">Sisa Cuti</div>
        <div class="p-2 bd-highlight">:</div>
        <div class="p-2 bd-highlight flex-grow-1 "><strong>3 Hari</strong></div>
    </div>
    <v-dialog />
    <alert-error ref="ae" />
    <form class="d-flex flex-column mt-4" method="POST" @submit.prevent="store()" style="min-height: 88vh;"> 
      <fieldset>
        <legend class="legend-input">Dari tanggal</legend>
        <input type="date" name="modal-input-pengirim" class="form-control" autocomplete="off" v-model="data.nama_tamu" required/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Sampai Tanggal</legend>
        <input type="date" name="modal-input-penerima" class="form-control" autocomplete="off" v-model="data.asal_instansi" required/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Keperluan</legend>
        <textarea name="tujuan" class="form-control" autocomplete="off" v-model="data.keperluan" required></textarea>
      </fieldset>   
       <!-- <fieldset class="mt-2">
        <legend class="legend-input">Tanggal Diambil</legend> 
        <input type="date" name="tanggal-diambil" class="form-control" autocomplete="off" v-model="dataModal.diambil_pada_tanggal" value="2020-09-15"/>
      </fieldset> -->
      <button class="btn-blue mt-3 mb-3" style="width:100%" :class="{'disable': isSubmit}" type="submit">
        <b>Ajukan</b>
      </button>
    </form>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import Api from "../../Api";
import alertError from "../../components/AlertError.vue";
// import VueClockPicker from '@pencilpix/vue2-clock-picker';
// import '@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.min.css'; 

export default {
  name: "addTamu",
  components: {
    alertError, 
  },
  data() {
    return {
      showError: false,
      startDate: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-"),
      isLoading: true,
      data: { 
        warna_kartu: '',
        nomor_kartu:'',
        user_id: '',
        nama_tamu: '',
        nama_driver: '',
        keperluan: '',
        workplace_id: '',
        file_upload:'',
        no_polisi: '',
        jenis_kendaraan: '4',
        jam_keluar: '',
        jam_masuk: '',
      },
      total: 0,
      dataModal: [],
      imgUploaded:'', 
      isModalVisible: false,
      updatedContent: "",
      token: "",
      myData: "",
      isSubmit: false,
      dataFiltered: 0
    };
  },

  async mounted() {
    this.token = JSON.parse(localStorage.getItem("token"));
    this.myData = JSON.parse(localStorage.getItem("cred"));
    // console.log(JSON.parse(localStorage.getItem('cred')));
  }, 
  methods: {
    // Preview Image
    onFileChange(e) {
      const file = e.target.files[0]; 
      this.imgUploaded = URL.createObjectURL(file);
      this.data.file_upload = e.target.files[0]
      // this.uploadActive = true
    },
    async store() { 
      if(this.data.jam_masuk == ''){
        this.showError = true
        return true
      }
      try { 
        this.data.user_id = this.myData.id;
        this.data.workplace_id = this.myData.id_tempat_kerja;
        let formData = new FormData();
        for (var key in this.data) { 
            console.log(key, this.data[key]);
            formData.append(key, this.data[key]);
        }  
        this.isSubmit = true

        await Api.post("satpam/tamu", formData, {
          headers: { 
            Authorization: "Bearer " + this.token,
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          this.$router.go(-1)
          // this.$router.push(this.$route.query.redirect || '/tamu')
          console.log(response);
            // this.done   = true;
        });
      } catch (error) {
        console.log(error);
      }
      this.isSubmit = false
    }
  }
};
</script> 
<style>
.clock-picker__input{
    min-width: 100% !important;
}
</style>